import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class UsersService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listUsers (filters) {
		const response = await this.axios.get("users/listUsers", { params: { filters }});
		return response.data;
	}

	async upsertUser (payload) {
		const response = await this.axios.post("users/upsertUser", payload);
		return response.data;
	}

	async deleteUser (id) {
		const response = await this.axios.delete("users/deleteUser", { params: id });
		return response.data;
	}

	async updateProfile (payload) {
		const response = await this.axios.post("users/updateProfile", payload);
		return response.data;
	}

	async getAllUsersSubscriptionRequired () {
		const response = await this.axios.get("users/getAllUsersSubscriptionRequired");
		return response.data;
	}
}
