<template>
	<div>
		<Template-base-empty class="containerCard">
			<div class="container-row-main" :style="'width: calc(100% + 30px) !important;'">
				<div class="container-head">
					<span class="container-head-title">Meu Perfil</span>
					<div class="container-salvar">
						<b-button
							class="salvar-button"
							@click="salvar({})"
							:disabled="invalidForm"
						>
							Salvar
						</b-button>
					</div>
				</div>
				<div class="container-user">
					<div class="head-row row-dark">
						<b-col cols="6" class="text-head-row">
							<label>
								Usuário
							</label>
						</b-col>
						<b-col cols="6" class="text-head-row">
							<b-form-input
								name="usuario"
								type="text"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.usuario"
								v-validate="{ required: true }"
								:state="validateState('usuario')"
							/>
						</b-col>

					</div>
				</div>
				<div class="container-user">
					<div class="head-row">
						<b-col cols="6" class="text-head-row">
							<label>
								Nome
							</label>
						</b-col>
						<b-col cols="6" class="text-head-row">
							<b-form-input
								name="nome"
								type="text"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.nome"
								v-validate="{ required: true }"
								:state="validateState('nome')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user">
					<div class="head-row row-dark">
						<b-col cols="6" class="text-head-row">
							<label>
								E-mail
							</label>
						</b-col>
						<b-col cols="6" class="text-head-row">
							<b-form-input
								name="email"
								type="text"
								autocomplete="off"
								class="input-text"
								:disabled="loading"
								v-model="usuario.email"
								v-validate="{ required: false }"
								:state="validateState('email')"
							/>
						</b-col>

					</div>
				</div>
				<div class="container-user mt-2">
					<div class="head-row mb-2">
						<b-col cols="6" class="text-head-row">
							<label>
								Senha
							</label>
						</b-col>
						<b-col cols="6" class="text-head-row">
							<InputSenha
								class="input-text"
								id="senha"
								name="senha"
								v-model="usuario.senha"
								v-validate="{ required: false }"
								:state="validateState('senha')"
								placeholder="Senha inalterada"
							/>
						</b-col>
					</div>
				</div>
			</div>
		</Template-base-empty>
	</div>
</template>

<script>
	import TemplateBaseEmpty from "@/templates/BaseEmpty";
	import { createHash } from "crypto";
	import { AuthService } from "../../services/auth";
	import InputSenha from "@/components/InputSenha";

	import { UsersService } from "../../services/users";
	import { hasFieldsWithErrors } from "@/helpers/validators";

	export default {
		components: {
			TemplateBaseEmpty,
			InputSenha
		},

		inject: ["parentValidator"],

		data () {
			return {
				ordensProducao: [],
				selectMaquinas: [],
				usuario: {
					id: null,
					usuario: null,
					nome: null,
					email: null,
					senha: null
				},

				loading: false,
				invalidForm: true,
				usersService: new UsersService(),
				authService: new AuthService(),

				errMsg: ""
			};
		},

		watch: {
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.isLoggedIn = await this.authService.isLoggedIn();
			if (!this.isLoggedIn)
				this.$router.push({ path: "/" });
			else
				this.findUser();
		},

		methods: {
			async findUser () {
				const { usuario, nomeUsuarioCompleto, email } = JSON.parse(localStorage.params);

				this.invalidForm = true;
				this.usuario.usuario = usuario;
				this.usuario.nome = nomeUsuarioCompleto;
				this.usuario.email = email;
				this.usuario.senha = null;
			},

			async salvar () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				await this.$snotify.async("Aguarde...", "Salvando!", async () => {
					try {
						const payload = {};
						payload.user = this.usuario.usuario;
						payload.name = this.usuario.nome;
						payload.email = this.usuario.email;
						if (this.usuario.senha)
							payload.password = createHash("sha512").update(this.usuario.senha).digest("hex");

						const usuarioLogado = await this.usersService.updateProfile({ payload });
						this.$store.commit("setUsuarioLogado", null);
						this.$store.commit("setUsuarioLogado", usuarioLogado);
						this.$router.replace(this.$route.params.next || "/");

						return {
							title: "Sucesso!",
							body: "Usuário salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar usuário!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.container-salvar {
		display: flex;
		width: 50%;
		height: 3rem;
		justify-content: right;
		margin-right: 8px;
	}

	.salvar-button {
		width: 135;
		height: 43;
		font-family: "Roboto Condensed Medium";
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.container-row-main {
		margin-left: -15px;
		background-color: #FFFFFF;
		border-radius: 10px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	}

	.container-head {
		display: flex;
		/* background-color: #6dcff6 ; */
		background-color: var(--cal-header-template);
		border-radius: 10px 10px 0 0;
		padding-top: 0.5rem;
		margin-bottom: 2rem;
	}

	.container-user {
		margin-left: 1.5rem;
	}

	.container-head-title {
		width: 50%;
		height: 3rem;
		margin-left: 16px;
		margin-top: -5px;
		font-family: "Roboto Condensed Medium";
		color: #FFFFFF;
		font-size: 30px;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: calc(100% - 24px);
		height: 4rem;
	}

	.row-dark {
		background-color: #eaebec;
	}

	.text-head-row {
		align-items: center;
		font-family: "Roboto Condensed Regular";
		font-size: 20px;
	}

	.input-text {
		align-items: center;
		font-family: "Roboto Condensed Regular";
		font-size: 20px;
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.custom-number-input {
		padding-right: 35px !important;
	}
</style>
